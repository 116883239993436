import Vue from 'vue'
import Transformer from './Transformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class QuestionTypeInputTransformer extends Transformer {

	table = 'question_type_input'

	transform (typeinput) {
		return {
			'typeinput_id'		: typeinput.typeinput_id,
			'typeinput_code'	: typeinput.typeinput_code,
			'typeinput_libelle'	: Vue.i18n.translate(typeinput.typeinput_libelle),
			'typeinput_icon'	: typeinput.typeinput_icon,
		}
	}
}
