import Transformer from './Transformer'
import ContactTransformer from './ContactTransformer'
/**
 * Retourne une liste de
 * @param {Dexie.Collection} actestypereferences
 */
export default class ActeTypeOperateurTransformer extends Transformer {

	table = 'horse_actes_type_operateur'

	contact(operateur) {
		return this.fetchRelationship(operateur, 'actetypeoperateur_contact')
	}

	async fetch(col) {
		this.additionalColumns({
			'contact': this.contact,
		})

        return col
	}

	async transform (operateur) {
		const contact = await ContactTransformer.process(operateur.contact)

		return {
			'actetypeoperateur_id'			: operateur.actetypeoperateur_id,
			'actetypeoperateur_actetype'	: operateur.actetypeoperateur_actetype,
			'actetypeoperateur_contact'		: operateur.actetypeoperateur_contact,
			'contact'						: contact
		}
	}
}
