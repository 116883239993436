<template>
	<main>
		<HeaderTab :title="componentTitle" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable 
                                :id_table="table_id"
								ref="table"
                                :items="items"
                                :busy.sync="table_busy"
                                primaryKey="actestype_id"
								:externSlotColumns="externSlot"
                            >
								<template v-slot:[`custom-slot-cell(actestype_maxdelay)`]="{ data }">
									{{ displayMaxdelayData(data.actestype_maxdelay) }}
								</template>
								<template v-slot:[`custom-slot-cell(active)`]="{ data }">
									<div class="custom-control custom-checkbox">
										<b-form-checkbox 
											name="check-button"
											switch 
											v-model="data.switch" 
											@change="switchActeType(data.id, !data.switch)"
										>
											<template v-if="data.switch">
												{{ $t('lieu.registry_enable') }}
											</template>
											<template v-else>
												{{ $t('lieu.registry_disable') }}
											</template>
										</b-form-checkbox>
									</div>
								</template>
								<template v-slot:[`custom-slot-cell(articles)`]="{ data }">
									<template v-for="article in data.articles">
										<span :key="article.articles_id">{{article.articles_label}}<br></span>
									</template>
									<a href="#" @click.prevent="addArticle(data.actestype_id)"><font-awesome-icon :icon="['far', 'plus']" /></a>
								</template>
							</CustomTable>
                        </div>
                    </div>
                </div>
		    </div>
		</div>
        <ArticleAjout v-if="canAdd" ref="ArticleAjout" :preselect_infos="preselect_infos" :accountingplan_id="accountingplan_id" @article_added="addLink"/>

		<ModalConfirm
			ref="delete-modal"
			:icon="['far', 'trash']"
			:text_title="$t('modal.actetype.suppression')"
			:text_question="$t('modal.actetype.confirmation_suppression')"
			:text_message="$t('modal.general.avertissement_suppression')"
			:text_button_ok="$t('action.supprimer')"
			:text_button_cancel="$t('modal.general.annuler')"
			:callback_validation="applyDeleteActesTypes"
		/>

		<b-modal ref="operateur-modal" hide-footer>
            <template v-slot:modal-title>
                 {{ $t("acte_type.changement_operateur") }}
            </template>

			<e-select 
            	v-model="contact"
                id="contact"
                track-by="contact_id"
                label="contact_label"
                :placeholder="$t('monte.selectionnez_contact')"
                :selectedLabel="$t('global.selected_label')"
                :options="contacts_formatted"
                :searchable="true"
                :allow-empty="true"
                :show-labels="false"
               	:multiple="true"
            >
            	<template slot="option" slot-scope="{ option }">{{ option.contact_label }}</template>
           		<template slot="singleLabel" slot-scope="{ option }">{{ option.contact_label }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
       		</e-select>

            <div class="text-center">
	            <b-button pill variant="primary" class="mt-3"  @click.prevent="saveOperateur"><font-awesome-icon :icon="['fal', 'save']" /> {{ $t("global.enregistrer") }}</b-button>
            </div>
        </b-modal>

		<b-modal ref="maxdelay-modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("acte_type.maxdelay_edit") }}
			</template>

			<label>{{ $t('acte_type.votre_maxdelay') }}</label>
			<input type="text" id="actestype_maxdelay" :class="maxdelayIsCorrect ? 'form-control' : 'form-control is-invalid'" v-model="actestype_maxdelay" autocomplete="chrome-off" :placeholder="$t('acte_type.maxdelay_placeholder')">

			<div class="text-center">
	            <b-button pill variant="primary" class="mt-3" :disabled="!maxdelayIsCorrect" @click.prevent="saveMaxdelay"><font-awesome-icon :icon="['fal', 'save']" /> {{ $t("global.enregistrer") }}</b-button>
            </div>

		</b-modal>
	</main>
</template>


<script type="text/javascript">
    import { EventBus } from "EventBus"
    import Navigation from "@/mixins/Navigation.js"
    import Actes from '@/mixins/Actes.js'
    import Accounting from '@/mixins/Accounting.js'
    import ActeType from '@/mixins/ActeType.js'
	import Question from "@/mixins/Question.js"
    import User from "@/mixins/User.js"
	import Contact from '@/mixins/Contact'

	export default {
		name: "ActeTypeManagement",
		mixins: [Navigation, ActeType, Actes, Question, User, Contact, Accounting],
		data () {
			return {
				componentTitle: this.getTrad('acte_type.gestion_acte_type'),
                items: null,
                canAdd: false,
                table_busy: true,
                accounting_plan: null,
                current_item: {},
                accountingplan_id: null,
				interracted_items: [],
				externSlot: [],
				preselect_infos: {},
				actestype_maxdelay: "",

				contact: null,
				contacts: [],

                events_tab: {
					'TableAction::EditActesTypes': this.applyEditActeType,
                    'TableAction::DeleteActesTypes': this.askDeleteActesTypes,
                    'TableAction::EditOperateurActeType' : this.askChangeOperateur,
                    'TableAction::EditMaxdelay' : this.askEditMaxdelay
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {

				this.externSlot = [
					'active',
					'actestype_maxdelay'
				]
				let acte_types_res = []

				if(this.userPermissions.indexOf("FACT") > -1) {
					this.externSlot.push('articles')
					this.accounting_plan = await this.loadAccountingPlanDefault()
					if(!this.accounting_plan) {
						this.accounting_plan = await this.loadFirstAccountingPlan()
						this.accountingplan_id = this.accounting_plan.accountingplan_id
					}
					else {
						this.accountingplan_id = this.accounting_plan.accountingplan_id
					}
					this.canAdd = true
					acte_types_res = await this.getActesTypesDetails('withGroupsAndArticles')
				}
				else {
					acte_types_res = await this.getActesTypesDetails()
				}

				this.items = null

                const acte_types = acte_types_res.map((el) => {
                    let acte = Object.assign({}, el)
                    acte.actestype_global = acte.actestype_licencekey === '' || acte.actestype_licencekey === null ? this.getTrad('global.oui') : this.getTrad('global.non');
                    acte.switch = acte.active
                    acte.id = acte.actestype_id
                    return acte
                })

                this.items = this.formatDataActeType(acte_types)
				this.table_busy = false

				this.contacts = await this.getAllContact(true)
			},

			async refresCustomTable() {
				this.table_busy = true
				this.init_component()
				this.$refs.table.refreshTable()
			},

            formatDataActeType(items) {
                items.forEach(item => {
                    item.id = item.actestype_id
                    item.isActive = false
                })

                return items
            },

			formatDataMaxdelay(maxdelay){
				return this.formatDataMaxdelay(maxdelay)
			},

			applyEditActeType(acte_type) {
				if(acte_type.global === true && !this.hasAdminAccess) {
					this.displayUnauthorizedToast()
					return
				}

				const params = { from: this.$route.name, acte_type_id: acte_type.actestype_id }
				this.$router.push({ name: 'acteTypeAjout', params: params })
			},

            askDeleteActesTypes(actes_types) {
				const at_global = actes_types.filter(at => at.global === true)

				if(at_global.length > 0 && !this.hasAdminAccess) {
					this.displayUnauthorizedToast()
					EventBus.$emit('TableAction::stopSpin')
					return
				}

				this.interracted_items = actes_types
				this.$refs['delete-modal'].openModal()
            },

			applyDeleteActesTypes() {
				const ids = this.interracted_items.map(type => type.actestype_id)

                this.deleteActesTypes(ids)
					.then(() => {
						this.successToast()
						this.refresCustomTable()
					})
					.catch(e => {
						console.error("ActeTypeManagement::applyDeleteActesTypes => ", e)
					})
					.finally(() => {
						this.interracted_items = []
						this.$refs['delete-modal'].closeModal()
						EventBus.$emit('TableAction::stopSpin')
					})
			},

			displayUnauthorizedToast() {
				this.infoToast('toast.global_act_type_cant_delete')
			},

            async switchActeType(id, val) {
                // si mon id est un nombre alors je switch une ligne, sinon je switch un groupe
                let res = false
                if(typeof id === "number") {
                    res = await this.saveActiveActe(id, val)
                }
                else {
                    res = await this.saveActiveGroupeActe(id, val)
                }
                if(res) {
                    this.successToast("toast.info_save_succes")
                }
            },

            async askChangeOperateur(acte_type) {
				this.interracted_items = acte_type
				this.contact = null
				const operateur = await this.getOperateurByActeType(acte_type.actestype_id)
				if(operateur.length > 0) {
					const operateur_ids = operateur.map(op => op.actetypeoperateur_contact)
					this.contact = this.contacts_formatted.filter(c => operateur_ids.includes(c.contact_id))
				}
				this.$refs['operateur-modal'].show()
            },

			askEditMaxdelay(actes_types){
				this.interracted_items = actes_types
				this.$refs['maxdelay-modal'].show()
			},

            async saveOperateur() {
            	const contacts_ids = this.contact.map(contact => contact.contact_id)
            	const res = await this.saveOperateurActeType(this.interracted_items.actestype_id, contacts_ids)

                if(res) {
                    this.successToast("toast.info_save_succes")
					this.$refs['operateur-modal'].hide()
                }
            },

			async saveMaxdelay(){
				const ids = this.interracted_items.map(type => type.actestype_id)
				await this.saveActesMaxdelay(ids, this.actestype_maxdelay)
				this.$refs['maxdelay-modal'].hide()
				this.successToast("toast.info_save_succes")
				await this.init_component()
			},

			async addArticle(actestype_id) {
				//Je vais chercher les comptas
				if(this.userPermissions.indexOf("FACT") > -1 && this.accounting_plan) {
				
					
					this.current_item = this.items.find(elem => elem.actestype_id == actestype_id)

					this.preselect_infos.articles_label = this.current_item.actestype_label.trim()
					this.preselect_infos.vat_value = "0.2000"
					this.preselect_infos.accounting_account = "706"

					this.$refs.ArticleAjout.addOrEdit()
                }
			},
			displayMaxdelayData(maxdelay){
				return this.displayMaxdelay(maxdelay)
			},
			async addLink(articles_id) {
				this.successToast("toast.ajout_acte_type_en_cours")
				this.$refs.ArticleAjout.$refs['modal'].hide()
				this.canAdd = false
				this.table_busy = true
				let result = await this.addLienActesTypeArticles(
                    this.current_item.actestype_id,
                    this.accounting_plan.accountingplan_id,
                    [articles_id],
                    [],
                    null,
					false
				);

                if(result) {
					await this.$sync.force(true, true)
					await this.init_component()
                }

				this.canAdd = true
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
            ArticleAjout: () => import('@/components/Accounting/ArticleAjout'),
		},
		computed: {
			hasAdminAccess() {
                return this.$store.state.userAccess.hasAdminAccess
            },
            table_id: function() {
                if(this.userPermissions.indexOf("ADMN") > -1) {
                    return 'acte_type_admin'
                }
                return 'acte_type'
            },
            contacts_formatted() {
            	let res = []
            	this.contacts.forEach(contact => {
					let contact_label = contact.contact_firstname + ' ' + contact.contact_lastname
					res.push({contact_id: contact.contact_id, contact_label: contact_label})
				})

				return res
            },
			maxdelayIsCorrect(){
				let formattedMaxDelay = this.formatMaxdelay(this.actestype_maxdelay)
				return this.checkMaxdelayFormat(formattedMaxDelay)
			}
		}
	};
</script>
