import Vue from 'vue'
import Transformer from './Transformer'
import ActeTypeTransformer from './ActeTypeTransformer'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} actes
 */
export default class ActeGroupeTransformer extends Transformer {

    table = 'horse_actes_groupe'

    fetchWithTypesLight (col) {
        return col.with({
            'actestypes'      : 'horse_actes_type'
        })
    }

    // tri par l'ordre demandé par Camille pour le headrTab du planning, à voir pour faire une fonction générique pour gérer d'autres ordres
    sortWithTypesLight (array) {
        let order_planning = ["REPRO", "SOINS", "TRAIT", "VAC", "VERM", "TEST", "ENTR", "TRANSP", "PERF", "VENTE", "EVAL"] 

        return array.sort((groupe_a, groupe_b) => {
            if (order_planning.indexOf(groupe_a.actesgroupe_code) < order_planning.indexOf(groupe_b.actesgroupe_code)){
                return -1;
            }
            if (order_planning.indexOf(groupe_a.actesgroupe_code) > order_planning.indexOf(groupe_b.actesgroupe_code)){
                return 1;
            }
            return 0;
        })
    }

    async transformWithTypesLight (groupe) {
        return {
            "actesgroupe_id": groupe.actesgroupe_id,
            "actesgroupe_code": groupe.actesgroupe_code,
            "actesgroupe_picto": groupe.actesgroupe_picto,
            "actesgroupe_label": Vue.i18n.translate('actes_groupe.' + groupe.actesgroupe_code),
            "actestypes": await ActeTypeTransformer.process(groupe.actestypes, 'withActive')
        }
    }

    fetchWithTypes (col) {
        return col.with({
            'actestypes'      : 'horse_actes_type'
        })
    }

    // tri par l'ordre demandé par Camille pour le headrTab du planning, à voir pour faire une fonction générique pour gérer d'autres ordres
    sortWithTypes (array) {
        let order_planning = ["REPRO", "SOINS", "TRAIT", "VAC", "VERM", "TEST", "ENTR", "TRANSP", "PERF", "VENTE", "EVAL"] 

        return array.sort((groupe_a, groupe_b) => {
            if (order_planning.indexOf(groupe_a.actesgroupe_code) < order_planning.indexOf(groupe_b.actesgroupe_code)){
                return -1;
            }
            if (order_planning.indexOf(groupe_a.actesgroupe_code) > order_planning.indexOf(groupe_b.actesgroupe_code)){
                return 1;
            }
            return 0;
        })
    }

    async transformWithTypes (groupe) {
        return {
            "actesgroupe_id": groupe.actesgroupe_id,
            "actesgroupe_code": groupe.actesgroupe_code,
            "actesgroupe_picto": groupe.actesgroupe_picto,
            "actesgroupe_label": Vue.i18n.translate('actes_groupe.' + groupe.actesgroupe_code),
            "actestypes": await ActeTypeTransformer.process(groupe.actestypes, 'withArticles')
        }
    }


    fetchWithTypesArticlesLight (col) {
        return col.with({
            'actestypes'      : 'horse_actes_type'
        })
    }

    // tri par l'ordre demandé par Camille pour le headrTab du planning, à voir pour faire une fonction générique pour gérer d'autres ordres
    sortWithTypesArticlesLight (array) {
        let order_planning = ["REPRO", "SOINS", "TRAIT", "VAC", "VERM", "TEST", "ENTR", "TRANSP", "PERF", "VENTE", "EVAL"] 

        return array.sort((groupe_a, groupe_b) => {
            if (order_planning.indexOf(groupe_a.actesgroupe_code) < order_planning.indexOf(groupe_b.actesgroupe_code)){
                return -1;
            }
            if (order_planning.indexOf(groupe_a.actesgroupe_code) > order_planning.indexOf(groupe_b.actesgroupe_code)){
                return 1;
            }
            return 0;
        })
    }


    async transformWithTypesArticlesLight (groupe) {
        return {
            "actesgroupe_id": groupe.actesgroupe_id,
            "actesgroupe_code": groupe.actesgroupe_code,
            "actesgroupe_picto": groupe.actesgroupe_picto,
            "actesgroupe_label": Vue.i18n.translate('actes_groupe.' + groupe.actesgroupe_code),
            "actestypes": await ActeTypeTransformer.process(groupe.actestypes, 'withArticlesLight')
        }
    }
}
