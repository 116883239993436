import Transformer from './Transformer'
import Vue from 'vue'

/**
 * Retourne une liste de
 * @param {Dexie.Collection} questions
 */
export default class QuestionTransformer extends Transformer {

	table = 'question'

	transform (question) {
		return {
			'question_id'			: question.question_id,
			'question_type'			: question.question_type,
			'question_fk'			: question.question_fk,
			'question_libelle'		: question.question_licencekey == "" ? Vue.i18n.translate('questions.' + [question.question_libelle]) : question.question_libelle,
			'question_placeholder'	: question.question_placeholder,
			'question_index'		: question.question_index,
			'question_questiontype'	: question.question_questiontype,
			'question_questiondata'	: question.question_questiondata,
			'question_code'			: question.question_code,
			'question_condition'	: question.question_condition
		}
	}

	sort (arr) {
		return arr.sortBy('question_index')
	}
}
