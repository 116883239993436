import Constants from '../../config.local.js'
import Config from '@/mixins/Config.js'
import Common from '@/assets/js/common.js'
import Vue from 'vue'
import ActeGroupeTransformer from '@/assets/js/dexie/transformers/ActeGroupeTransformer.js'
import ActeTypeTransformer from '@/assets/js/dexie/transformers/ActeTypeTransformer.js'
import QuestionTransformer from '@/assets/js/dexie/transformers/QuestionTransformer.js'
import ActeTypeOperateurTransformer from '@/assets/js/dexie/transformers/ActeTypeOperateurTransformer.js'

var ActeTypeMixin = {
    mixins: [Config],
    methods: {

        /**************************/
        /* METHODES RELATIVES AUX */
        /*      ACTES TYPES       */
        /*************************/

        prepareQuestionsTab: function(questions) {
            const question_filled = this.deppCloneObj(questions)

            question_filled.forEach(question => {
                question.question_questiondata = JSON.stringify(question.question_questiondata)
            })

            return question_filled
		},

        //Sauvegarde un acte type en base de données (mise à jour si l'id est spécifié et valide)
        saveActeType: async function(codegroupe, label, codetype, type_id, global = false, need_ordonnance = false) {
            const params = { acte_type_id: type_id }
            const body = {
                codegroupe: codegroupe,
                codetype: codetype,
                label: label,
                global: global,
                need_ordonnance: need_ordonnance,
            }
            const url = (type_id===null || type_id===undefined ? Constants.ACTES_TYPES_USER_URL : this.constructRoute(Constants.ACTE_UPDATE_TYPE_URL, params)) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("ActesMixin::getActeQuestions", url, body, false)
        },

		deleteActeTypes: async function(actestype_ids) {
            await this.$storage.db.t('horse_actes_type')
				.then(table => {
					return table.where('actestype_id')
					.anyOf(actestype_ids)
					.invalid()
				})
        },

        // Sauvegarde des questions de l'acte-type
        saveActeTypeQuestions: async function(acte_type_id, questions, global = false) {
            const params = {
                acte_type_id: acte_type_id
            }
            const body = {
                questions: questions,
                global: global
            }
            const url = this.constructRoute(Constants.ACTES_GET_QUESTIONS_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("ActesMixin::getActeQuestions", url, body, false)
		},

        // Sauvegarde pour un ou plusieurs actes un nouveau délai de validité maximum
        saveActesMaxdelay: async function(actestype_ids, actestype_maxdelay) {
            actestype_maxdelay = this.formatMaxdelay(actestype_maxdelay) // Formatage uniforme maxdelay en BDD
            await this.$storage.db.t('horse_actes_type_maxdelay')
            .then(table => {
                return table.where('actestypemaxdelay_actestype').anyOf(actestype_ids).invalid()
            })
            
            return await Common.asyncForEach(actestype_ids, async (actestype_id) => {
                return await this.$storage.db.t('horse_actes_type_maxdelay')
                .then(table => {
                    return table.add({
                        actestypemaxdelay_id: Common.getNegativeId(),
                        actestypemaxdelay_actestype: actestype_id,
                        actestypemaxdelay_delay: actestype_maxdelay,
                        actestypemaxdelay_valide: 1
                    })
                })
            })
		},
		
		getActesTypesDetails: async function(transformer = 'withGroups') {
			return this.$storage.db.t('horse_actes_type')
			.then(table => {
				return table.orderBy('actestype_label')
			})
			.then(col => {
				return col.transform(new ActeTypeTransformer(transformer))
			})
        },

        //Suppression d'une liste d'actes-types
        deleteActesTypes: async function(actes_types_ids) {
            const params = { }
            const body = {
                actes_types : actes_types_ids
            }
            const url = this.constructRoute(Constants. ACTES_TYPES_DISABLE_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const res = await this.$request.request_post_api("ActesTypesMixin::deleteActesTypes", url, body, false)
				.then(async (res) => {
					// Si la suppression en ligne s'est bien passée (avec les vérifs nécessaires), on supprime en local
					await this.deleteActeTypes(actes_types_ids)
					return res
				})
				.catch(e => {
					console.error("ActesTypeMixin::deleteActesTypes", e)
					return null
				})

            return res
        },

        // Formate les actes type avec les articles liés
        format_actes_type_articles: function(articles, actes_type) {
            if(articles.length > 0 && actes_type.length > 0) {
                let actes_type_articles = []

                actes_type.forEach(type => {
                    const temp_type = this.deppCloneObj(type)
                    temp_type.articles_label = ''
                    temp_type.articles_code = ''
                    temp_type.articles = []

                    articles.forEach(article => {
                        const temp_article = article.actes_type.filter(type_article => type_article.actestype_id == type.actestype_id)[0]
                        if(temp_article) {
                            temp_type.articles.push(article)
                            temp_type.articles_label = temp_type.articles_label + article.articles_label + "<br>"
                            temp_type.articles_code = temp_type.articles_code + article.articles_code + "<br>"
                        }
                    })

                    actes_type_articles.push(temp_type)
                })

                return actes_type_articles
            } else {
                return actes_type
            }
        },

        // Ajoute un lien actestype/article
        addLienActesTypeArticles: async function(actestype_id, accountingplan_id, articles, default_article, triggered_article, quantifiable) {
            const params = { 'acte_type_id': actestype_id }
            const url = this.constructRoute(Constants.ACTES_TYPE_ARTICLES_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
            const infos = {
                "accountingplan_id": accountingplan_id,
                "articles": articles,
                "default_article": default_article,
                "triggered_article": triggered_article,
                "quantifiable": quantifiable
            }

            const response = await this.$request.request_post_api("ActesTypesMixin::addLienActesTypeArticles", url, infos, false)
            .catch(e => {
                console.error("ActesTypesMixin::addLienActesTypeArticles", e)
                this.failureToast("toast.info_save_failed")
            })

            return response
        },

        saveActiveActe: async function(actestype_id, active) {
            await this.$storage.db.t('horse_actes_type_active')
                .then(table => {
                    return table.where('actestypeactive_actestype').equals(parseInt(actestype_id)).invalid()
                })

            return await this.$storage.db.t('horse_actes_type_active')
                .then(table => {
                    return table.add({
                        actestypeactive_id: Common.getNegativeId(),
                        actestypeactive_actestype: actestype_id,
                        actestypeactive_active: active,
                        actestypeactive_valide: 1
                    })
                })
        },

        saveActiveGroupeActe: async function(groupe, active) {
            let groupes = await this.$storage.db.t('horse_actes_groupe')
            .then(table => {
                return table.where({actesgroupe_label: groupe})
            })
            .then(col => {
                return col.transform(new ActeGroupeTransformer('withTypes'))
            })

            return await Common.asyncForEach(groupes[0].actestypes, async (actes_type) => {
                await this.saveActiveActe(actes_type.actestype_id, active)
            })
		},
		
		getBlackListedActesTypes: async function() {
			const url = Constants.ACTES_TYPES_BLACKLISTED_URL+'?licence_key='+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_get_api("ActeTypeMixin::getBlackListedActesTypes", url)
			.catch(e => {
				return false
			})

			if(response.code_retour === 0) {
				return response.retour
			}
		},

		addBlackListedActesTypes: async function(actestypes_ids) {
			const params = {actes_types_ids: actestypes_ids }
			const url = Constants.ADD_ACTES_TYPES_BLACKLISTED_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_post_api("ActeTypeMixin::addBlackListedActesTypes", url, params, false)
			.catch(e => {
				return false
			})

			if(response.code_retour === 0){
				return response.retour
			}
		},

		deleteBlackListedActesTypes: async function(actestypes_ids) {
			const params = {actes_types_ids: actestypes_ids }
			const url = Constants.DELETE_ACTES_TYPES_BLACKLISTED_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_delete_api("ActeTypeMixin::deleteBlackListedActesTypes", url, params)
			.catch(e => {
				return false
			})

			if(response.code_retour === 0){
				return response.retour
			}
		},

		getUsefullActTypes: async function() {
			let res = await this.$storage.db.t('horse_actes_type')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
				    return col.transform(new ActeTypeTransformer('withFirstAct'))
				})

			return res.filter(actestype => actestype.first_act !== null)
		},

		getUsefullActTypesFinished: async function() {
			let res = await this.$storage.db.t('horse_actes_type')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
				    return col.transform(new ActeTypeTransformer('withFirstActFinished'))
				})

			return res.filter(actestype => actestype.first_act_finished !== null)
		},

        getOperateurByActeType: async function(actetype_id, withInfo = false) {
            return await this.$storage.db.t('horse_actes_type_operateur')
                .then(table => {
                    return table.where('actetypeoperateur_actetype').equals(parseInt(actetype_id))
                })
                .then(col => {
                    if(withInfo) {
                        return col.transform(new ActeTypeOperateurTransformer())
                    }
                    return col.toArray()
                })
        },

        changeOperateur: async function(actetype_id, contact_id) {
            await this.$storage.db.t('horse_actes_type_operateur')
                .then(table => {
                    return table.where('actetypeoperateur_actetype').equals(parseInt(actetype_id)).invalid()
                })

            return await this.$storage.db.t('horse_actes_type_operateur')
                .then(table => {
                    return table.add({
                        actetypeoperateur_id: Common.getNegativeId(),
                        actetypeoperateur_actetype: actetype_id,
                        actetypeoperateur_contact: contact_id,
                        actetypeoperateur_valide: 1
                    })
                })
        },

        saveOperateurActeType: async function(actetype_id, contact_ids) {
            await this.$storage.db.t('horse_actes_type_operateur')
                .then(table => {
                    return table.where('actetypeoperateur_actetype').equals(parseInt(actetype_id)).invalid()
                })

            await Common.asyncForEach(contact_ids, async (id) => {
                await this.$storage.db.t('horse_actes_type_operateur')
                .then(table => {
                    return table.add({
                        actetypeoperateur_id: Common.getNegativeId(),
                        actetypeoperateur_actetype: actetype_id,
                        actetypeoperateur_contact: id,
                        actetypeoperateur_valide: 1
                    })
                })
            })

            return true
        },

        getActeTypeDG: async function() {
            return await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where({ actestype_code: 'REPRO_DIAGNOSTIC_DE_GESTATION' }).first()
            })
        },

        getQuestionsDG: async function() {
            let acte_type = await this.getActeTypeDG()

            return await this.$storage.db.t('question')
            .then(table => {
                return table.where({ 
                    question_fk: acte_type.actestype_id
                })
            })
            .then(col => {
                return col.transform(new QuestionTransformer())
            })
        },

        getResponseDG: async function() {
            let acte_type = await this.getActeTypeDG()

            let question = await this.$storage.db.t('question')
            .then(table => {
                return table.where({ 
                    question_fk: acte_type.actestype_id,
                    question_libelle: 'Résultat'
                }).first()
            })

            return question.question_questiondata.data
        },
        
        getActeTypeIAC: async function() {
            return await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where({ actestype_code: 'REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL' }).first()
            })
        },
        
        getActeTypeCollecte: async function() {
            return await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where({ actestype_code: 'REPRO_COLLECTE_DEMBRYON' }).first()
            })
        },

        getActeTypeSaillie: async function() {
            const actes_type_saillie = [
                "REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_CONGEL",
                "REPRO_INSMINATION_EN_RFRIGR",
                "REPRO_INSMINATION_ARTIFICIELLE_EN_RFRIGR_TRANSPORT",
                "REPRO_SAILLIE_EN_MAIN",
                "REPRO_SAILLIE_EN_LIBERT",
            ]

            const actes_type = await this.$storage.db.t('horse_actes_type')
            .then(table => {
                return table.where('actestype_code').anyOf(actes_type_saillie).toArray()
            })

            for(let i in actes_type) {
                actes_type[i].actestype_label = this.getTrad('actes_type.'+actes_type[i].actestype_code)
            }

            return actes_type
        },

        getQuestionsByActeType: async function(actetype_id) {
            return await this.$storage.db.t('question')
            .then(table => {
                return table.where({ 
                    question_fk: actetype_id
                })
            })
            .then(col => {
                return col.transform(new QuestionTransformer())
            })
        },

        disableActType: async function(principal_activity) {
            const params = {principal_activity: principal_activity}
			const url = Constants.DISABLE_ACT_TYPE_URL + "?licence_key="+Constants.USER_LICENCE_KEY
			const response = await this.$request.request_post_api("ActeTypeMixin::disableActType", url, params, false)
			.catch(e => {
				return false
			})

			if(response.code_retour === 0){
				return response.retour
			}
        },

        // Test si format valide de maxdelay (ex: 1y 1m 1w 1d)
        checkMaxdelayFormat(maxdelay){
            if(!maxdelay || maxdelay == '') {
                return true
            }
            maxdelay = maxdelay.split(' ')
            let test = true
            let regex = /^[0-9]+(y|m|w|d)/
            maxdelay.forEach(delay => {
                if (!regex.test(delay)){
                    test = false
                }
            })
            return test
        },

        formatMaxdelay(maxdelay){     
            if(!maxdelay) return       
            return maxdelay.replace('a', 'y')
            .replace('s', 'w')
            .replace('j', 'd')
        },

        displayMaxdelay(maxdelay){
            if(!maxdelay || Vue.i18n.locale() === "en") return       
            return maxdelay.replace('y', 'a')
            .replace('w', 's')
            .replace('d', 'j')
        }
	}
}

export default ActeTypeMixin
