import Constants from '../../config.local.js'
import Common from '@/assets/js/common'

var ResultatAnalyse = {
    methods: {
    	async getResultTypeByActe(acte_code) {
    		return await this.$storage.db.t('horse_actes_result_type')
	            .then(table => {
	                return table.where({
	                	resulttype_actetype: acte_code, 
	                }).first()
				})
    	},

        async getResultTypeById(type_id) {
            return await this.$storage.db.t('horse_actes_result_type')
                .then(table => {
                    return table.get(parseInt(type_id))
                })
        },

       /**
        * pour chaque couple analyse - matrice : 
        *  - je regarde si le résultat correspondant existe 
        *       -> s'il existe je l'ajoute à ma liste keep
        *       -> sinon je crée le résultats + les réponses et j'ajoute à keep
        *  à la fin je supprime tous les résultats (et leurs réponses) de l'acte qui ne sont pas dans keep
        */
    	async saveResultAnalyse(resulttype_id, acte_id, reponses, analyses, matrices, horse_id) {
            analyses = analyses.filter(analyse => analyse !== null)
            matrices = matrices.filter(matrice => matrice !== null)
            if(analyses.length == 0 || matrices == 0) {
                return false
            }
            await this.$storage.db.transaction('rw', 
                [
                    'horse_actes_result', 
                    'reponse', 
                ], 
                async () => {

                    let keep_result = [] // résultat que je garde à la fin du traitement
                    await Common.asyncForEach(analyses, async (analyse) => {
                        await Common.asyncForEach(matrices, async (matrice) => {
                            const res = await this.$storage.db.t('horse_actes_result')
                            .then(table => {
                                return table.where({
                                    result_type: resulttype_id,
                                    result_acte: acte_id,
                                    result_analyse: analyse,
                                    result_prelevement: matrice,
                                    result_horse: horse_id,
                                }).first()
                            })

                            if(!res) {
                                const result_id = Common.getNegativeId()
                                await this.$storage.db.t('horse_actes_result')
                                    .then(table => {
                                        return table.add({
                                            result_id: result_id,
                                            result_type: resulttype_id,
                                            result_acte: acte_id,
                                            result_analyse: analyse,
                                            result_prelevement: matrice,
                                            result_horse: horse_id,
                                            result_valide: 1
                                        })
                                    })
                                await this.saveReponsesResult(reponses, result_id)

                                keep_result.push(result_id)
                            }
                    		else {
                                keep_result.push(res.result_id)
                            }
                        })
                    })

                    // je supprime les résulats qui ne servent plus
                    await this.$storage.db.t('horse_actes_result')
                        .then(table => {
                            return table.where('result_id').noneOf(keep_result)
                                .and(res => res.result_acte == acte_id)
                                .invalid()
                        })

                    // je supprime les réponses des résulats qui ne servent plus
                    // await this.$storage.db.t('reponse')
                    //     .then(table => {
                    //         return table.where('reponse_fk').noneOf(keep_result)
                    //             .and(rep => rep.reponse_type == "horse_actes_result")
                    //             .invalid()
                    //     })
            })
    	},

        async saveResultAnalyseLot(results) {
            const url = Constants.SAVE_ANALYSE_RESULT_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            await this.$request.request_post_api("ResultatAnalyse::saveResultAnalyseLot", url, {results}, false)
            .catch(error => {
                console.error("ResultatAnalyse::saveResultAnalyseLot => ERROR", error)
                return null
            })
        },

        async updateResultAnalyse(result_ids, reponses) {
            result_ids.forEach(async result_id => {
                await this.saveReponsesResult(reponses, result_id)
            })
        },

        async updateResultAnalyseLot(results) {
            const url = Constants.UPDATE_ANALYSE_RESULT_URL + "?licence_key="+Constants.USER_LICENCE_KEY
            await this.$request.request_post_api("ResultatAnalyse::updateResultAnalyseLot", url, {results}, false)
            .catch(error => {
                console.error("ResultatAnalyse::updateResultAnalyseLot => ERROR", error)
                return null
            })
        },

    	async saveReponsesResult(reponses, result_id) {
            const id = this.$sync.replaceWithReplicated('horse_actes_result', result_id)
            await this.$storage.db.t('reponse')
            .then(table => {
                return table.where({
                    reponse_fk: parseInt(id),
                    reponse_type: 'horse_actes_result'
                }).invalid()
            })

            if (reponses) {
                await Common.asyncForEach(reponses, async (reponse) => {
                    await this.$storage.db.t('reponse')
                    .then(table => {
                        return table.add({
                            reponse_id: Common.getNegativeId(),
                            reponse_question: reponse.reponse_question,
                            reponse_fk: parseInt(id),
                            reponse_type: 'horse_actes_result',
                            reponse_valide: 1,
                            reponse_data: reponse.reponse_data,
                            reponse_data_hidden: reponse.reponse_data_hidden
                        })
                    })
                })
            }
        },

        async getResultByActe(acte_id) {
        	return await this.$storage.db.t('horse_actes_result')
	            .then(table => {
	                return table.where({
	                	result_acte: acte_id, 
	                }).toArray()
				})
        },

        async getSanitaireResult(actetype_id, start, end) {
            const url = this.constructRoute(Constants.RESULTAT_SANITAIRE_URL, {actetype_id, start, end}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("ResultatAnalyse::getSanitaireResult", url)
            .catch(error => {
                console.error("ResultatAnalyse::getSanitaireResult => ERROR", error)
                this.failureToast("toast.info_lecture_failed")
                return null
            })
            return result.retour
        },

        async getSanitaireResultByHorse(horse_id) {
            const url = this.constructRoute(Constants.RESULTAT_SANITAIRE_HORSE_URL, {horse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("ResultatAnalyse::getSanitaireResultByHorse", url)
            .catch(error => {
                console.error("ResultatAnalyse::getSanitaireResultByHorse => ERROR", error)
                this.failureToast("toast.info_lecture_failed")
                return null
            })
            return result.retour
        },

        async createAnalyse(actes_ids, demandeur_id, facture_id, horse_nom) {
            let ids = actes_ids
            // si j'ai un ids négatifs je force la synchro et remplace les ids
            if(actes_ids.some(id => id < 0)) {
                await this.$sync.force(true)
                ids = this.$sync.replaceWithReplicated('horse_actes', actes_ids)
            }

            const url = this.constructRoute(Constants.GENERATE_ANALYSE_URL, { actes_ids: ids, tiers1_id: demandeur_id, tiers2_id:facture_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_get_api("ResultatAnalyse::createAnalyse", url)
            .catch(error => {
                console.error("ResultatAnalyse::createAnalyse => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "analyse_"+horse_nom+".pdf")
            return true
        },

        async getDemandeAnalyseByDate(start_date, end_date) {
            const url = this.constructRoute(Constants.LIST_DEMANDE_ANALYSE, {start_date, end_date}) + "?licence_key="+Constants.USER_LICENCE_KEY

            const result = await this.$request.request_get_api("ResultatAnalyse::getDemandeAnalyseByDate", url)
            .catch(error => {
                console.error("ResultatAnalyse::getDemandeAnalyseByDate => ERROR", error)
                this.failureToast("toast.info_lecture_failed")
                return null
            })
            return result.retour
        },

        async editAnalyse(analyse_id, demandeur_id, facture_id, horse_nom) {
            const params = { demandeur_id, facture_id }

            const url = this.constructRoute(Constants.EDIT_ANALYSE_URL, { analyse_id }) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("ResultatAnalyse::editAnalyse", url, params, false)
            .catch(error => {
                console.error("ResultatAnalyse::editAnalyse => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "analyse_"+horse_nom+".pdf")
            return true
        }
    }
}

export default ResultatAnalyse
