import Constants from '../../config.local.js'
import QuestionTransformer from '@/assets/js/dexie/transformers/QuestionTransformer'
import QuestionTypeInputTransformer from '@/assets/js/dexie/transformers/QuestionTypeInputTransformer'
import ReponseTransformer from '@/assets/js/dexie/transformers/ReponseTransformer'

var QuestionMixin = {
    methods: {

        // Chargement des types
        loadTypesInput: async function() {
			return this.$storage.db.t('question_type_input')
			.then(table => {
				return table.toCollection()
			})
			.then(col => {
				return col.transform(new QuestionTypeInputTransformer())
			})
        },

        // Conversion de format pour les questions
		formatDistantQuestionToLocal: async function(questions) {
			const input_types = await this.loadTypesInput()
            let counter = 1
            questions.forEach(question => {
                const input_question_type = input_types.filter(input_type => input_type.typeinput_code == question.question_questiontype)

                if(input_question_type.length > 0) {
                    question.question_icon = input_question_type[0].typeinput_icon
                    question.question_index = counter
                    counter += 2
                }
            })

            return questions
        },
        // Charge un ensemble de réponses pour les questions d'un acte à partir de son id
		getAnswers: async function(reponse_type, reponse_fk) {
			return this.$storage.db.t('reponse')
			.then(table => {
				return table.where({
					reponse_fk: parseInt(reponse_fk),
					reponse_type: reponse_type
				})
			})
			.then(col => {
				return col.transform(new ReponseTransformer())
			})
		},

        // Récupération des questions liées à un acte (dans l'ordre par index)
        getQuestions: async function(question_type, question_fk) {
			return this.$storage.db.t('question')
			.then(table => {
				return table.where({
					question_fk: parseInt(question_fk),
					question_type
				})
			}).then(col => {
				return col.transform(new QuestionTransformer())
			})
		},

		getQuestionWebService: async function(url_ws) {
			const url = Constants.BASE_API_URL + "/" +  url_ws
			return await this.$request.request_get_api("ActesTypeMixin::getQuestionWebService", url)
		},
	}
}

export default QuestionMixin
